'use client';

import { useEffect } from 'react';

/**
 * These effects are intended to run once per navigation on the website.
 */
export default function PageSetup() {
    useEffect(() => {
        const payload = {
            url: window.location.href,
            referrer: document?.referrer ?? window.frames?.top?.document?.referrer,
            statusCode: 200,
        };

        void fetch('/api/page-view', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, []);

    return null;
}
